.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your images */
  .my-masonry-grid_column > div {
    background: grey;
    margin-bottom: 30px;
  }
  
  img {
    width: 100%;
    display: block;
  }
  