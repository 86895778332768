.testimonial .items {
  padding: 30px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.testimonal .img {
  width: 100px;
  height: 100px;
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6}

  .testimonal .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:20px;
   
 
  }
  @media screen and(max-width:769px) {
    .testimonal .content{
      grid-template-columns: repeat(1,1fr);
    }
}
@media screen and(max-width:1024px) {
  .testimonal .content{
    grid-template-columns: repeat(2,1fr);
  }
}

.testimonal .content .items{
  min-height: 250px;
  display: flex;
  flex-direction: column;
justify-content: space-between;
}