* {
    box-sizing: border-box;
  }
  
  /* Create two unequal columns that floats next to each other */
  /* Left column */
  .leftcolumn {   
    float: left;
    width: 75%;
  }
  
  /* Right column */
  .rightcolumn {
    float: left;
    width: 25%;
    padding-left: 20px;
  }
  
  /* Fake image */
  /* .fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
  } */
  
  /* Add a card effect for articles */
  .card {
     background-color: white;
     padding: 20px;
     margin-top: 20px;
  }
  
  /* Clear floats after the columns */
  .rows:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 800px) {
    .leftcolumn, .rightcolumn {   
      width: 100%;
      padding: 0;
    }
  }
  

  .imgblog{
    width: 100%;
    max-height: 528px;
    object-fit: fill;
  }