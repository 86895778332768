.hero {
  background-image: url("../../../../public/banner.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 90vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
@media screen and (max-width: 1270px) {
  .hero {
    padding-top: 30%;
  }
  .hero .container .row{
    width: 100%;
  }
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.hero > * {
  position: relative;
  z-index: 1;
}

.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: 72%;
    padding-top: 36%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
 .heading-subtitle{
  font-size: 16px ;
 }
 .heading-title{
  font-size: 32px !important  ;
 }
}

@media screen and (max-width: 768px) {
.hero{
  padding-top:240px
}
}
@media screen and (max-width: 461px) {
  .hero{
    padding-top:200px
  }
  }