/* Modal styling */
.modal {
  display: flex; /* Show the modal */
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.7); /* Darker background */
  justify-content: center; /* Center the modal */
  align-items: center; /* Center the modal */
  backdrop-filter: blur(5px); /* Optional: add blur effect */
}

.modal-content {
  background-color: #fff;
  margin: 15% auto; 
  padding: 30px; /* Increased padding for a spacious feel */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  width: 90%; /* Responsive width */
  max-width: 500px; /* Maximum width */
}

.close {
  color: #ff4d4d; /* Red color for close button */
  float: right;
  font-size: 28px;
  font-weight: bold;
  transition: color 0.2s; /* Smooth transition for hover effect */
}

.close:hover,
.close:focus {
  color: #c70000; /* Darker red on hover */
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px; /* More space between form groups */
}

.form-group label {
  display: block;
  margin-bottom: 8px; /* Increased space for labels */
  font-weight: bold; /* Bold labels */
}

.form-group input, 
.form-group textarea {
  width: 100%;
  padding: 12px; /* More padding for inputs */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Slightly rounded edges */
  box-sizing: border-box;
  transition: border 0.3s; /* Smooth border transition */
}

.form-group input:focus, 
.form-group textarea:focus {
  border: 1px solid #28a745; /* Green border on focus */
  outline: none; /* Remove default outline */
}

button.submit-btn {
  background-color: #28a745; /* Green background */
  color: white;
  border: none;
  padding: 12px 24px; /* Increased padding for buttons */
  border-radius: 5px; /* Slightly rounded edges */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition */
}

button.submit-btn:hover {
  background-color: #218838; /* Darker green on hover */
}
