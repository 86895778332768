.view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  display: flex;
  justify-content: space-evenly;
}
.view-wrapper {
  position: relative;
  display: flex;
}
.view-image {
  object-fit: contain;
  width: auto;
}
.view-btn {
  position: absolute;
  cursor: pointer;
  padding: 24px;
  top: 40%;
}
.btn-next {
  right: 0;
}
.btn-prev {
  left: 0;
}
.view-btn i {
  font-size: 64px;
  color: rgba(240, 240, 240, 0.8);
}

@media only screen and (max-width: 1126px) {
  .view-image {
    width: 100% !important;
  }
}
@media only screen and (max-width: 769px) {
  .view-image {
    width: 100% !important;
  }
  .view-btn i {
    font-size: 32px;
  }
}
